import * as D3 from "d3"

// mostly a copypasta of https://observablehq.com/@d3/stacked-bar-chart

export default (selection, props) => {
    const {
        series,
        width,
        height,
        margin,
        keysScaleAccessor,
        colorScale,
        isHorizontal,
        tooltipClass,
        mobile
    } = props;

    const t = selection.transition()
        .duration(333);

    series.forEach(d => d.forEach(v => v.key = d.key));
    const keys = series.map(d => d.key);

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    /**
     * Keys axis
     */
    const keysScale = D3.scaleBand()
        .domain(series[0].map(d => keysScaleAccessor(d.data)))
        .range([isHorizontal ? innerHeight : margin.left, isHorizontal ? margin.top : innerWidth - margin.right])
        .padding(0.1);

    const keysAxisFn = g => g
        .attr("transform", isHorizontal ? `translate(${margin.left},0)` : `translate(0,${height - margin.bottom})`)
        .call(isHorizontal ? D3.axisLeft(keysScale).tickSizeOuter(0) : D3.axisBottom(keysScale).tickSizeOuter(0));
        // .call(g => g.selectAll(".domain").remove());

    selection.selectAll(".keysAxis")
        .data([null])
        .join(
            enter => enter
                .append("g")
                .attr("class", "keysAxis")
                .call(keysAxisFn),
            update => update
                .transition(t)
                .call(keysAxisFn)
        );

    /**
     * Values axis
     */
    const valuesScale = D3.scaleLinear()
        .domain([0, D3.max(series, d => D3.max(d, d => d[1]))])
        .rangeRound([isHorizontal ? margin.left : innerHeight, isHorizontal ? innerWidth - margin.right : 0]);

    const valuesAxisFn = g => g
        .attr("transform", isHorizontal ? `translate(0, ${margin.top})`: `translate(${margin.left},0)`)
        .call(isHorizontal ? D3.axisTop(valuesScale).ticks(null, "s") : D3.axisLeft(valuesScale).ticks(null, "s"));
        // .call(g => g.selectAll(".domain").remove());

    selection.selectAll(".valuesAxis")
        .data([null])
        .join(
            enter => enter
                .append("g")
                .attr("class", "valuesAxis")
                .call(valuesAxisFn),
            update => update
                .transition(t)
                .call(valuesAxisFn)
        );

    /**
     * Tooltip
     */
    let tooltipDiv = D3.select(`.${tooltipClass}`);
    const setupMouseHandler = selection => {
        selection
            .on("mouseover", () => {
                let s = tooltipDiv.transition()
                    .duration(200)
                    .style("opacity", .9);
                if (mobile) {
                    s.transition()
                    .duration(3000)
                    .style("opacity", 0)
                }
            })
            .on("mousemove", (d, i) => {
                tooltipDiv.html(`${d.key} - ${d.data[d.key]}`)
                    .style("left", (D3.event.pageX) + "px")
                    .style("top", (D3.event.pageY - 28) + "px");
            })
            .on("mouseout", () => {
                tooltipDiv.transition()
                    .duration(500)
                    .style("opacity", 0)
            });
    };

    /**
     * Rectangles
     */
    const rectFn = rect => rect
        .attr("x", d => isHorizontal ? valuesScale(isHorizontal ? d[0] : d[1]) : keysScale(keysScaleAccessor(d.data)))
        .attr("y", d => isHorizontal ? keysScale(keysScaleAccessor(d.data)) : valuesScale(d[1]))
        .attr(isHorizontal ? "width"  : "height", d => valuesScale(isHorizontal ? d[1] : d[0]) - valuesScale(isHorizontal ? d[0] : d[1]))
        .attr(isHorizontal ? "height" : "width", keysScale.bandwidth())

    selection.selectAll(".rectSeries")
        .data(series, d => d.key)
        .join(
            enter => enter
                .append("g")
                .attr("class", "rectSeries")
                .attr("fill", d => colorScale(d.key))
                .selectAll("rect")
                .data(d => d, d => keysScaleAccessor(d.data))
                .join(
                    enter => enter
                        .append("rect")
                        .call(rectFn)
                        .call(setupMouseHandler)
                ),
            update => update
                .selectAll("rect")
                .data(d => d, d => keysScaleAccessor(d.data)) // update the bound data reference to the new one
                .call(setupMouseHandler)
                    .transition(t)
                    .call(rectFn)
        );

    /**
     * Legend
     */
    const depth = margin.left + 25;
    selection.selectAll(".legend")
        .data([keys], d => d)
        .join(
            enter => {
                enter = enter
                    .append("g")
                    .attr("class", "legend")
                    .attr("font-family", "sans-serif")
                    .attr("font-size", isHorizontal ? 12 : 10)
                    .attr("text-anchor", isHorizontal ? "end" : "start")
                    .selectAll("g")
                    .data(keys)
                    .join("g")
                    .attr("transform", (d, i) => isHorizontal ? `translate(0,${250 + i * 20})` : `translate(0,${i * 20})`);
                enter
                    .append("rect")
                    .attr("x", isHorizontal ? width - 19 : depth)
                    .attr("width", 19)
                    .attr("height", 19)
                    .attr("fill", colorScale);
                return enter
                    .insert("text")
                    .attr("x", isHorizontal ? width - 24 : depth + 22)
                    .attr("y", 9.5)
                    .attr("dy", "0.32em")
                    .text(d => d);
            }
        );
}
