import React, { useRef } from "react"
import { Image } from "semantic-ui-react"
import * as D3 from "d3"
import SEO from "../../../components/seo"
import useContainerDimensions from "../../../components/useContainerDimensions"
import stackedBarChart from "../../../components/stackedBarChart"
import styles from "./vegan-society-trademarks.module.css"
import logo from "./VeganTM-Palette1-VEGAN-GREEN.png"
import "../../general.css"
import {
    chartTitleTextStyle,
    vizBackgroundStyle
} from "../../../defines";

const data = require("../../../data/vegan-society-trademarks-cumulative.json").trademarks;

const Title = "The Vegan Society’s Vegan Trademark";

const VeganSocietyTrademarks = ({ mobile }) => {
    const keys = Object.keys(data[0]).filter(k => k !== "Year");
    const series = D3.stack()
        .keys(keys)(data);

    let GraphAspectRatio = 4 / 3;
    if (mobile) {
        GraphAspectRatio = 5 / 6;
    }

    const chartDivRef = useRef();
    const { width } = useContainerDimensions(chartDivRef);
    const svgWidth = Math.max((width - 10), 0);
    const svgHeight = Math.max((width - 10) / GraphAspectRatio, 0);

    const colorScale = D3.scaleOrdinal()
        .domain(["Animal Care", "Baby and Infant", "Fashion and Textiles", "Food and Drink", "Healthcare and Household", "Lab Tests and Raw Materials", "Toiletries and Cosmetics"])
        .range(["#004E46", "#8169EA", "#008AAB", "#FF9E17", "#AB004F", "#EC5469", "#008275"]);

    const renderChart = () => {
        if (typeof document === "undefined") {
            return;
        }

        const chartSvg = D3.select(".chart");
        let barChartG = chartSvg.select(".barChartG");
        if (barChartG.empty()) {
            barChartG = chartSvg.append("g");
            barChartG.attr("class", "barChartG");
        }
        barChartG.call(stackedBarChart, {
            series,
            width: svgWidth,
            height: svgHeight,
            margin: mobile ? { left: 30, top: 20, right: 0, bottom: 0 } : { left: 35, right: 0, top: 0, bottom: 20 },
            keysScaleAccessor: d => d.Year,
            colorScale,
            isHorizontal: mobile,
            tooltipClass: mobile ? styles.tooltipMobile : styles.tooltip,
            mobile
        });
    }

    renderChart();

    return (
        <>
            <SEO title={Title} />
            <div className="contentDiv">
                <h2 className="chartTitle" style={chartTitleTextStyle(mobile)}>{Title}</h2>
                <div className="vizBackground" style={vizBackgroundStyle(mobile)}>
                    <div className={mobile ? styles.tooltipMobile : styles.tooltip} style={{ opacity: 0 }} />
                    <Image size="small" src={logo} style={{margin:"auto"}}/>
                    <div className="FlexGrow" ref={chartDivRef} style={{ textAlign: "center", marginTop: 20 }}>
                        <svg className="chart" width={`${svgWidth}px`} height={`${svgHeight}px`} />
                    </div>
                    <div className="citation" style={{ width: "90%", margin: "auto" }}>
                        
                        <p style={{ marginTop: "10px" }}>
                            The <a href="https://www.vegansociety.com/">Vegan Society</a> is the oldest vegan organisation in the world. They established the international Vegan
                            Trademark in 1990 to improve product labelling. The Trademark is the oldest and most recognised symbol of third-party
                            accreditation for vegan labelling.
                        </p>
                        <p>
                            The Trademark helps people identify a product that is free from animal ingredients and has not been tested on
                            animals. As of October 2020, The Vegan Society has registered over 46,000 products, from 2,000 brands, across 76
                            countries.
                        </p>
                        <p>
                            Despite registering products with the Vegan Trademark since 1990, 86% of total registrations have come from rapid
                            growth in the last five years. This upward trend includes both high-turnover businesses, as well as new
                            start-ups and completely vegan brands.
                        </p>
                        <p>
                            The Vegan Trademark registers food and drink, cosmetics, toiletries, household, healthcare, animal care, lab tests,
                            fashion and textiles, and baby and infant specific items. While previously the focus would have been firmly on food
                            and drink items, as of October 2020, the leading category for registrations is cosmetics and toiletries.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VeganSocietyTrademarks;
